










































































































import Vue from "vue";
import VLineChart from "@/components/vuemarc-ui-kit/charts/VLineChart.vue";
import UsersService from "@/api/users/usersService";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import AnalyticsAllVerificationFlowsSkeleton from "@/components/skeletons/analytics/AnalyticsAllVerificationFlowsSkeleton.vue";
import { sendSentryError } from "@/lib/utils";

export default Vue.extend({
  name: "AnalyticsAllVerificationFlows",
  components: {
    VLineChart,
    VTab,
    AnalyticsAllVerificationFlowsSkeleton,
  },
  props: {
    startDate: { required: true, type: [Date, String] },
    endDate: { required: true, type: [Date, String] },
    groupBy: { required: true, type: String },
    verifications: { required: true, type: Object },
  },

  data() {
    return {
      loading: false,
      activeTabName: "",
      activeTabNameFlowType: "KYC",
      activeTabNameChart: "",
      selectedFlowVerifications: [] as Array<any>,
      kybVerificationFlows: [] as Array<any>,
      kycVerificationFlows: [] as Array<any>,
      cdpVerificationFlows: [] as Array<any>,
      analyticsVerificationsPromise: null as any,
    };
  },
  watch: {
    activeTabName: {
      handler() {
        this.getAnalytics(this.activeTabName);
      },
    },
    activeTabNameFlowType: {
      handler() {
        const verificationFlows = this.getActiveVerificationFlowCompanies(
          this.activeTabNameFlowType
        );

        if (verificationFlows.length) {
          this.activeTabName = verificationFlows[0].id;
        }
      },
    },
    activeTabNameChart: {
      handler() {
        this.$emit("getChartAnalytics", {
          activeTabName: this.activeTabNameChart,
          verifications: this.selectedFlowVerifications,
        });
      },
    },
  },
  computed: {
    hasVerifications(): boolean {
      return (
        !!this.kycVerificationFlows.length || !!this.kybVerificationFlows.length
      );
    },
    flowTypeCopy(): string {
      const map: Record<string, string> = {
        KYC: "settings_verification_flows_type_kyc",
        KYB: "settings_verification_flows_type_kyb",
        CDP: "settings_verification_flows_type_cdp",
      };
      return (
        map[this.activeTabNameFlowType] ||
        "settings_verification_flows_type_kyc"
      );
    },
    nodetext(): string {
      const map: Record<string, string> = {
        KYC: "mainnav_users",
        KYB: "mainnav_companies",
        CDP: "mainnav_cdp",
      };
      return map[this.activeTabNameChart] || "mainnav_users";
    },
    companyKYCTotalCount(): number {
      return this.kycVerificationFlows.reduce(
        (total, verificationFlow) =>
          total + verificationFlow.totalVerifications,
        0
      );
    },
    companyKYBTotalCount(): number {
      return this.kybVerificationFlows.reduce(
        (total, verificationFlow) =>
          total + verificationFlow.totalVerifications,
        0
      );
    },
    companyCDPTotalCount(): number {
      return this.cdpVerificationFlows.reduce(
        (total, verificationFlow) =>
          total + verificationFlow.totalVerifications,
        0
      );
    },
  },
  methods: {
    getActiveVerificationFlowCompanies(activeTabName: string) {
      switch (activeTabName) {
        case "KYC":
          return this.kycVerificationFlows;
        case "KYB":
          return this.kybVerificationFlows;
        case "CDP":
          return this.cdpVerificationFlows;
        default:
          return this.kycVerificationFlows;
      }
    },
    filterAndParseVerifications(companies: Array<any>) {
      let totalCountOfVerification = 0;
      for (const company of companies) {
        for (const verification of company.verifications) {
          for (const timePeriod of verification.time_periods) {
            if (timePeriod.count !== 0)
              totalCountOfVerification += timePeriod.count;
          }
          if (totalCountOfVerification) {
            if (verification.type === "Verified Users") {
              verification.type = "verified_users";
              company.totalVerifications = totalCountOfVerification;
            }
            if (verification.type === "CDP") {
              company.totalVerifications = totalCountOfVerification;
              this.cdpVerificationFlows.push(company);
            }
            if (verification.type === "REPR") {
              company.totalVerifications = totalCountOfVerification;
              this.kybVerificationFlows.push(company);
            }
            verification.totalCount = totalCountOfVerification;
            totalCountOfVerification = 0;
          }
        }
        if (this.isKYCVF(company.totalVerifications, company.id))
          this.kycVerificationFlows.push(company);
      }
    },
    isKYCVF(totalVerifications: number, companyId: string) {
      return (
        totalVerifications &&
        !this.kybVerificationFlows.some(
          (verificationFlow: any) => verificationFlow.id === companyId
        ) &&
        !this.cdpVerificationFlows.some(
          (verificationFlow: any) => verificationFlow.id === companyId
        )
      );
    },
    getAnalytics(activeTabName: string) {
      const verificationFlows = this.getActiveVerificationFlowCompanies(
        this.activeTabNameFlowType
      );
      const { verifications } = verificationFlows.find(
        (verificationFlow: any) => verificationFlow.id === activeTabName
      );
      this.selectedFlowVerifications =
        this.filterAndParseVerificationsTabs(verifications);
      this.activeTabNameChart = this.selectedFlowVerifications[0].type;
      this.$emit("getChartAnalytics", {
        activeTabName: this.activeTabNameChart,
        verifications: this.selectedFlowVerifications,
      });
    },
    filterAndParseVerificationsTabs(verifications: Array<any>) {
      const updatedVerifications = [];
      let totalCountOfVerification = 0;
      for (const verification of verifications) {
        for (const timePeriod of verification.time_periods) {
          if (timePeriod.count !== 0)
            totalCountOfVerification += timePeriod.count;
        }
        if (totalCountOfVerification) {
          if (verification.type === "Verified Users")
            verification.type = "verified_users";
          verification.totalCount = totalCountOfVerification;
          updatedVerifications.push(verification);
          totalCountOfVerification = 0;
        }
      }
      return updatedVerifications;
    },

    async getAllAnalytics(): Promise<any> {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      endDate.setDate(endDate.getDate() + 1);
      const searchParams: URLSearchParams = new URLSearchParams();
      const fromDateString = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      const toDateString = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      searchParams.set("from_date", fromDateString);
      searchParams.set("to_date", toDateString);
      searchParams.set("group_by", this.groupBy);
      if (!this.$store.state.company?.id) {
        sendSentryError(
          "AnalyticsAllVerificationFlows: Company id is undefined"
        );
        return;
      }
      this.analyticsVerificationsPromise =
        UsersService.getTotalVerificationsFromCompany(
          searchParams,
          this.$store.state.company.id
        );

      return this.analyticsVerificationsPromise;
    },
    async loadData() {
      this.loading = true;
      this.kybVerificationFlows = [];
      this.kycVerificationFlows = [];
      this.cdpVerificationFlows = [];
      const { company_apps } = await this.getAllAnalytics();
      this.filterAndParseVerifications(company_apps);
      const verificationFlows = this.getActiveVerificationFlowCompanies(
        this.activeTabNameFlowType
      );

      if (verificationFlows.length) {
        this.activeTabName = verificationFlows[0].id;
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
  beforeDestroy() {
    this.analyticsVerificationsPromise.reject();
  },
});
