













import Vue from "vue";

export default Vue.extend({
  name: "AnalyticsVerificationsSummaryItem",
  props: {
    summaryValue: { required: true, type: Number },
    status: { required: true, type: String },
    loading: { required: false, default: false, type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    isKYBFlow() {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "KYB"
      );
    },
    isCDPFlow() {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "CDP"
      );
    },
  },
  methods: {
    navigateAndFilter(status: string) {
      const verificationFlowId = this.$store.state.verificationFlow.app_id;
      let query: { verification_flow_id: string; filters?: string } = {
        verification_flow_id: verificationFlowId,
      };

      if (this.isKYBFlow) {
        query.filters =
          status === "PENDING" ? `status=IN_PROGRESS` : `status=${status}`;
        this.$router.push({
          name: "companies",
          query,
        });
      } else if (this.isCDPFlow) {
        if (status === "NOT_PROCESSED" || status === "IN_PROGRESS") {
          query.filters = `processing_status=RUNNING&processing_status=ERROR`;
        } else {
          query.filters = `status=${status}`;
        }
        this.$router.push({
          name: "complex-document-processor",
          query,
        });
      }
    },
  },
});
